import { Popover } from "@headlessui/react"
import { ChevronDownIcon } from "@heroicons/react/outline"
import React from "react"

const PageCategory = ({allCategories, selectedCategory, setSelectedCategory, filterText, setFilterText, children}) => {
	return (
		<div className="relative w-full">
			<div className="inline-flex w-2/3">
				<div className="xl:hidden mt-2">
					<Popover className="relative w-full w-auto">
						{({ open, close }) => (
							<>
								<Popover.Button className="inline-flex w-full justify-center bg-white text-neutral items-center p-2 border border-white-300 rounded-md">
									{allCategories?.find(e => e.catId === selectedCategory)
										?.categoryName || selectedCategory}
									<ChevronDownIcon
										className={open ? "rotate-180 transform w-5" : "w-5"}
									/>
								</Popover.Button>

								<Popover.Panel className="left-0 top-full absolute justify-center mt-2 w-80 z-10 rounded-md shadow-lg bg-white ring-1 ring-white ring-opacity-5">
									<div className="py-1">
										{allCategories.map((category, index) => (
											<a
                                                key={category}
												className={`cursor-pointer block px-4 py-2 text-sm hover:bg-gray-200 ${
													selectedCategory === category.catId && "bg-blue-200"
												}`}
												onClick={() => {
													close()
													setSelectedCategory(category.catId)
												}}
											>
												{category.categoryName}
											</a>
										))}
									</div>
								</Popover.Panel>
							</>
						)}
					</Popover>
				</div>

				<ul className="hidden xl:flex flex-wrap mb-px text-sm font-medium text-center text-gray-500 dark:text-gray-400">
					{allCategories.map((category, index) => (
						<li className="me-2" key={index}>
							<a
								onClick={() => {
									setSelectedCategory(category.catId)
								}}
								className={`cursor-pointer inline-flex items-center justify-center p-4 group ${
									selectedCategory === category.catId
										? "border-b-2 border-blue-600 text-blue-600 dark:border-blue-500 dark:text-blue-500 font-bold"
										: "border-b-2 border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 text-neutral font-bold"
								}`}
							>
								{category.categoryName}
							</a>
						</li>
					))}
				</ul>
			</div>

			<div className="w-1/3 justify-end inline-flex float-right mt-2">
				<input
					type="text"
					className="rounded-md z-10 w-5/6 border-0 py-1.5 pl-2 pr-4 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
					placeholder={`Search ${children ? "resources" : "news"} by name, date, or excerpt...`}
					value={filterText}
					onChange={e => setFilterText(e.target.value)}
				/>
				{children}
			</div>
		</div>
	)
}

export default PageCategory
