import React, { useEffect, useState } from "react"

const YoutubeComponent = ({ blok: { Youtube } }) => {
	const [videoId, SetVideoId] = useState("")
	useEffect(() => {
		if (Youtube?.startsWith("https://www.youtube.com/watch?v=")) {
			const urlParams = new URLSearchParams(Youtube.split("?")[1])
			SetVideoId(urlParams.get("v"))
		} else if (Youtube?.startsWith("https://youtu.be/")) {
			SetVideoId(Youtube.split("/")[3])
		}
	}, [Youtube])

	return (
		<iframe
			className="responsive-iframe"
			src={`https://www.youtube.com/embed/${videoId}`}
			title="YouTube video player"
			frameborder="0"
			allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
			allowfullscreen
		/>
	)
}

export default YoutubeComponent
