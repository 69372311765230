import AboutUs from "./aboutUs"
import AdjustableComponent from "./adjustableComponent"
import AllResourcesList from "./allResourcesList"
import CallToAction from "./callToAction"
import CardBlock from "./cardBlock"
import CenterText from "./centerText"
import ContactDetails from "./contact-details"
import ExpandablePanel from "./expandablePanel"
import Feature from "./feature"
import Grid from "./grid"
import Hero from "./hero"
import HomeEvents from "./homeEvents"
import HomeNews from "./homeNews"
import HomePageCallToAction from "./homePageCallToAction"
import HomePageImage from "./homePageImage"
import ImageSection from "./imageSection"
import ImageText from "./imageText"
import MembershipList from "./membershipList"
import MoreInformation from "./moreInformation"
import RecentNewsList from "../news/recentNewsList"
import ResourceItem from "./resourceItem"
import RichText from "./richText"
import RtdFilterList from "./rtdFilterList"
import SbEditable from "storyblok-react"
import Table from "./table"
import Teaser from "./teaser"
import Testimonial from "./testimonial"
import TileBlock from "./tileBlock"
import Undefined from "./undefined"
import StaticItem from "./staticItem"
import CarouselBlock from "./carouselBlock"
import Carousel from "./carousel"
import ImageCarousel from "./imageCarousel"
import ImageCarouselBlock from "./imageCarouselBlock"
import YoutubeComponent from "./youtubeComponent"

const Components = {
	callToAction: CallToAction,
	contactDetails: ContactDetails,
	expandablePanel: ExpandablePanel,
	feature: Feature,
	grid: Grid,
	hero: Hero,
	table: Table,
	newsList: RecentNewsList,
	richText: RichText,
	teaser: Teaser,
	testimonial: Testimonial,
	membershipList: MembershipList,
	homePageCallToAction: HomePageCallToAction,
	homePageImage: HomePageImage,
	imageSection: ImageSection,
	homeEvents: HomeEvents,
	homeNews: HomeNews,
	adjustableComponent: AdjustableComponent,
	allResourcesList: AllResourcesList,
	resourceItem: ResourceItem,
	aboutUs: AboutUs,
	tileBlock: TileBlock,
	centerText: CenterText,
	moreInformation: MoreInformation,
	imageText: ImageText,
	cardBlock: CardBlock,
	rtdFilterList: RtdFilterList,
	staticItem: StaticItem,
	carouselBlock: CarouselBlock,
	imageCarouselBlock: ImageCarouselBlock,
	carousel: Carousel,
	imageCarousel: ImageCarousel,
	youtubeComponent: YoutubeComponent,
}

const DynamicComponent = ({ blok, isRTDPage }) => {
	if (typeof Components[blok.component] !== `undefined`) {
		const Component = Components[blok.component]
		return (
			<SbEditable content={blok}>
				{isRTDPage ? (
					<div data-theme="rtdTheme">
						<Component blok={blok} isRTDPage={isRTDPage} />
					</div>
				) : (
					<Component blok={blok} isRTDPage={isRTDPage} />
				)}
			</SbEditable>
		)
	}
	return <Undefined name={blok.component} props={blok} />
}

export default DynamicComponent
