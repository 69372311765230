import tw, { styled } from "twin.macro"

import NavLink from "./navLink"
import React from "react"

const CallToAction = ({ blok, text, link }) => {
	text ||= blok?.text
	link ||= blok?.link

	let color = blok?.textColour || "text-white"

	return text ? (
		<div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
			<div className={`${color} rounded-md shadow`}>
				<NavLink
					className={`no-underline w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded bg-accent hover:opacity-80 md:py-4 md:text-lg md:px-10`}
					title={text}
					isSocialLink={false}
					link={link}
					button
				/>
			</div>
		</div>
	) : (
		<></>
	)
}

export default CallToAction
