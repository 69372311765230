import React, { useRef } from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import axios from "axios"
import { Link, navigate } from "gatsby"
import { useAuth0 } from "@auth0/auth0-react"
import { DocumentIcon } from "@heroicons/react/outline"

const RenderGatsbyImage = ({ image, permalink }) => {
	const linkUrl = `/news/${permalink}`

	return (
		<div>
			<Link to={linkUrl}>
				<GatsbyImage
					className="h-48 w-full object-cover"
					image={image}
					alt={`Thumbnail for news item`}
				/>
			</Link>
		</div>
	)
}

const Resource = ({
	resource,
	useCardStyle,
	clickToDownload = false,
	thumbnailUrl,
	category,
}) => {
	const { getIdTokenClaims } = useAuth0()
	const {
		permalink,
		authorizedOn,
		name,
		file,
		fileName,
		author,
		thumbnailImages,
		abstract,
	} = resource
	const image =
		thumbnailImages &&
		thumbnailImages.childImageSharp &&
		getImage(thumbnailImages.childImageSharp)
	const checkImg = image || image !== 0 ? image : null
	const newsPage = category === "news"

	const handleDownload = async response => {
		const contentType = response.headers["content-type"]
		const url = window.URL.createObjectURL(
			new Blob([response.data], { type: contentType })
		)
		const link = document.createElement("a")
		link.href = url
		link.setAttribute("download", fileName)
		document.body.appendChild(link)
		link.click()
	}

	const readBlobText = async ({ response }) => {
		const text = await new Response(response.data).text()
		alert(text)
	}

	const handleClick = async e => {
		e.preventDefault()

		if (!clickToDownload) {
			navigate(`/${category ?? "resources"}/${permalink}`)
			return
		}

		const tokenClaims = await getIdTokenClaims()

		await axios
			.get(
				`${process.env.GATSBY_API_BASE_URL}/Resources/DownloadResource/${file}`,
				{
					headers: {
						Authorization: `Bearer ${tokenClaims?.__raw}`,
					},
					responseType: "blob",
				}
			)
			.then(handleDownload)
			.catch(readBlobText)
	}

	const ResourceCard = () => {
		const containerRef = useRef(null)

		return (
			<div className="cursor-pointer resource-card-container product-wrapper items-center hover:opacity-80  bg-white">
				<div className="resource-card" onClick={handleClick}>
					<div
						className={`relative overflow-hidden w-auto w-full ${
							thumbnailUrl ? "h-60" : "max-h-60"
						} justify-center`}
						ref={containerRef}
					>
						{thumbnailUrl ? (
							<img
								className="object-scale-down w-full w-auto h-full"
								src={thumbnailUrl}
								alt=""
							/>
						) : category === "news" && checkImg ? (
							<RenderGatsbyImage image={image} permalink={permalink} />
						) : (
							<div className="flex items-center justify-center text-neutral w-full h-full text-gray-500">
								<DocumentIcon className="w-12 h-12" /> {/* File icon */}
							</div>
						)}
					</div>
					<div className="p-5">
						<div className="card-content text-gray-500">
							<p>
								{author ? `${author} |` : ""}
								{authorizedOn
									? new Intl.DateTimeFormat("en-AU", {
											timeZone: "Australia/NSW",
											year: "numeric",
											month: "short",
											day: "2-digit",
									  }).format(new Date(authorizedOn))
									: ""}
							</p>
						</div>
						<h1 className="line-clamp-2 text-md text-neutral font-bold">
							{name}
						</h1>
						{abstract && newsPage && <p className="line-clamp-4">{abstract}</p>}
					</div>
				</div>
			</div>
		)
	}

	if (useCardStyle) {
		return <ResourceCard />
	}

	return (
		<div>
			<a className="text-blue-500" href="" onClick={handleClick}>
				{name}
			</a>
		</div>
	)
}

export default Resource
