import React from "react"
import RichText from "./richText"

const ImageCarousel = ({ blok, carouselData, visibleImages }) => {
	return (
		// single image corousel
		// <div key={carouselData._uid} className="w-fit min-w-full p-2 bg-gray-200">
		// 	<div className="flex w-full justify-center items-center flex-col">
		// 		<div style={{ height: "350px" }}>
		// 			<RichText blok={blok} content={carouselData.icon} />
		// 		</div>
		// 	</div>
		// </div>
		<div
			key={carouselData._uid}
			style={{ width: `${100 / visibleImages}%` }}
			className="flex-shrink-0"
		>
			<div key={carouselData._uid} className="w-fit min-w-full p-1">
				<div className="flex w-full justify-center items-center flex-col">
					<RichText blok={blok} content={carouselData.icon} />
				</div>
			</div>
		</div>
	)
}

export default ImageCarousel
