import { graphql, useStaticQuery } from "gatsby"
import React, { useEffect, useState } from "react"
import Resource from "../resources/resource"
import { updateResources } from "./resourcesHelper"
import ResourceFilter from "./resourceFilter"
import PageCategory from "../../templates/page-category"
import { useAuth0 } from "@auth0/auth0-react"

const AllResourcesList = () => {
	const data = useStaticQuery(graphql`
		query AllFreeResourcesQuery {
			allFreeResources {
				nodes {
					name
					fileName
					description
					authorizedOn
					categories
					author
					file
					permalink
					abstract
					displayOnAllResourcePage
					thumbnail {
						thumbnailUrl
						modified
						url
					}
				}
			}

			allResourceInterestGroups {
				nodes {
					fileId
					interestGroups
				}
			}

			allInterestGroup {
				nodes {
					interestGroupId
					name
					permalink
					isRTDFilter
				}
			}
		}
	`)

	useEffect(() => {
		const searchParams = new URLSearchParams(window.location.search)
		const topicParam = searchParams.get("topic")

		if (topicParam) {
			const modifiedTopicParam = `RTD - ${topicParam.replace(" and ", " & ")}`
			setSelectedCategory("Roads & Transport Directorate")
			setTimeout(() =>
				setSelectedFilters({ ...selectedFilters, topics: [modifiedTopicParam] })
			)
		}
	}, [])

	const categoriesWithName = [
		{ categoryName: "All Resources", catId: "All Resources" },
		{
			categoryName: "Roads & Transport Directorate",
			catId: "Roads & Transport Directorate",
		},
		{ categoryName: "Members Technical Papers Library", catId: "408740002" },
		{ categoryName: "Student Research Library", catId: "408740000" },
	]
	const allResources = updateResources(data)

	const [filterText, setFilterText] = useState("")
	const [filteredResources, setFilteredResources] = useState(allResources)

	const [selectedFilters, setSelectedFilters] = useState({
		authors: [],
		topics: [],
		dates: [],
	})
	const [selectedQuries, setSelectedQuries] = useState({
		authors: "",
		topics: "",
		dates: "",
	})
	const [filterOptions, setFilterOptions] = useState({
		authors: [],
		topics: [],
		dates: [],
	})

	const [selectedCategory, setSelectedCategory] = useState("All Resources")
	const [catFilteredResources, setCatFilteredResources] = useState([])
	const { isAuthenticated, isLoading, loginWithRedirect, user } = useAuth0()

	//Global serch functinality
	useEffect(() => {
		// Update filteredResources when filterText or allResources changes
		setFilteredResources(
			filterText
				? catFilteredResources.filter(resource => {
						const lowercaseFilterText = filterText.toLowerCase()
						const lowercaseName = resource.name.toLowerCase()
						const lowercaseCreatedDate = resource.authorizedOn
							? resource.authorizedOn.toLowerCase()
							: ""
						const lowercaseAuthor = resource.author
							? resource.author.toLowerCase()
							: ""

						return (
							lowercaseName.includes(lowercaseFilterText) ||
							lowercaseCreatedDate.includes(lowercaseFilterText) ||
							lowercaseAuthor.includes(lowercaseFilterText)
						)
				  })
				: catFilteredResources
		)
	}, [filterText])

	// when user changes categories tab & if filter resources value is updated  Topics, Authors and Years value is also update with updated data
	const updateFilterOptions = filtered => {
		const sortedAuthors = Array.from(
			new Set(filtered.map(resource => resource.author))
		)
			.sort()
			.filter(e => e)

		const sortedTopics = Array.from(
			new Set(
				filtered
					.map(a => a.interestGroups)
					.flat()
					.map(a => a?.name)
			)
		)
			.sort()
			.filter(a => a)

		setFilterOptions({
			authors: sortedAuthors,
			topics: sortedTopics,
			dates: extractUniqueYears(filtered),
		})
	}

	const formatDate = dateString => {
		const options = { year: "numeric" }
		return new Date(dateString).toLocaleDateString("en-US", options)
	}

	const extractUniqueYears = resources => {
		const yearsSet = new Set(
			resources.map(resource => formatDate(resource.authorizedOn))
		)
		return Array.from(yearsSet).sort((a, b) => b - a) // Sort years in descending order
	}

	useEffect(() => {
		setSelectedQuries({ authors: "", topics: "", dates: "" })
		setSelectedFilters({ authors: [], topics: [], dates: [] })
	}, [selectedCategory])

	useEffect(() => {
		let filtered = []

		if (selectedCategory) {
			if (selectedCategory === "All Resources") {
				filtered = allResources
					.filter(group => group.displayOnAllResourcePage)
					.map(res => {
						res.interestGroups = res?.interestGroups?.filter(
							int => !int?.isRTDFilter
						)
						return res
					})
			} else if (selectedCategory === "Roads & Transport Directorate") {
				filtered = allResources
					.filter(group => group.displayOnAllResourcePage)
					.map(res => {
						res.interestGroups =
							Array.isArray(res.interestGroups) && res.interestGroups.length > 0
								? res.interestGroups.filter(int => int?.isRTDFilter)
								: []

						// Skip the resource if interestGroups is empty after filtering
						if (res.interestGroups.length === 0) {
							return null // or return an empty object
						}

						return res
					})
					.filter(res => res !== null)
			} else {
				filtered = allResources.filter(
					res => res.categories && res.categories.includes(selectedCategory)
				)
			}

			updateFilterOptions(filtered)
		}

		filtered = (selectedCategory ? filtered : allResources).filter(resource => {
			const matchesAuthor =
				selectedFilters.authors.length === 0 ||
				selectedFilters.authors.includes(resource.author)
			const matchesDate =
				selectedFilters.dates.length === 0 ||
				selectedFilters.dates.includes(formatDate(resource.authorizedOn))
			const matchesTopics =
				selectedFilters.topics.length === 0 ||
				resource?.interestGroups?.some(g =>
					selectedFilters.topics.includes(g.name)
				)

			return matchesAuthor && matchesDate && matchesTopics
		})
		setCatFilteredResources(filtered)
		setFilteredResources(filtered)
	}, [selectedFilters])

	return (
		<>
			<div className="ml-4 mr-4">
				<PageCategory
					allCategories={categoriesWithName}
					selectedCategory={selectedCategory}
					setSelectedCategory={setSelectedCategory}
					filterText={filterText}
					setFilterText={setFilterText}
				>
					<ResourceFilter
						setSelectedFilters={setSelectedFilters}
						setSelectedQuries={setSelectedQuries}
						selectedFilters={selectedFilters}
						selectedQuries={selectedQuries}
						filterOptions={filterOptions}
					/>
				</PageCategory>
			</div>

			<div className="mt-4">
				{filteredResources.length === 0 ? (
					<p className="items-center justify-center">No single match found.</p>
				) : (
					<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-3 bg-gray-200 p-3">
						{filteredResources.map(r => (
							<Resource
								key={r.file}
								resource={r}
								useCardStyle={true}
								thumbnailUrl={
									r.thumbnail &&
									r.thumbnail.length > 0 &&
									r.thumbnail[0]?.thumbnailUrl
								}
							/>
						))}
					</div>
				)}
			</div>
		</>
	)
}

export default AllResourcesList
