import React, { useEffect, useRef, useState } from "react"
import ImageCarousel from "./imageCarousel"
import RichText from "./richText"

const ImageCarouselBlock = ({ blok }) => {
	const intervalImgRef = useRef(null)
	const [activeImgCarousel, setActiveImgCarousel] = useState(0)
	const [visibleImages, setVisibleImages] = useState(8)

	useEffect(() => {
		startInterval()
		return () => clearInterval(intervalImgRef.current)
	}, [])

	const startInterval = () => {
		clearInterval(intervalImgRef.current)
		intervalImgRef.current = setInterval(() => {
			nextImgSlide()
		}, 5000)
	}

	useEffect(() => {
		updateVisibleImages()
		window.addEventListener("resize", updateVisibleImages)

		return () => {
			window.removeEventListener("resize", updateVisibleImages)
		}
	}, [])

	const updateVisibleImages = () => {
		const width = window.innerWidth

		if (width >= 1536) {
			setVisibleImages(8) // 8 images for extra-large screens
		} else if (width >= 1280) {
			setVisibleImages(7) // 7 images for large screens
		} else if (width >= 1024) {
			setVisibleImages(6) // 6 images for medium-large screens
		} else if (width >= 768) {
			setVisibleImages(4) // 4 images for tablets
		} else if (width >= 640) {
			setVisibleImages(3) // 3 images for small tablets
		} else {
			setVisibleImages(1) // 1 image for mobile screens
		}
	}

	const nextImgSlide = () => {
		setActiveImgCarousel(prevIndex => {
			const nextIndex = prevIndex + 1
			if (nextIndex === blok.blocks.length) {
				return 0 // Reset to first image when last image is reached
			}
			return nextIndex
		})
		//single pic carousel
		// setActiveImgCarousel(previndex => (previndex + 1) % blok.blocks.length)
	}

	const goToNextImg = index => {
		setActiveImgCarousel(index)
	}

	const stopInterval = () => {
		clearInterval(intervalImgRef.current)
	}

	const resumeInterval = () => {
		startInterval()
	}

	return (
		<>
			{/* single pic img carousel maybe used for mobile screens */}
			{/* <div key={blok._uid} className="flex justify-center items-center">
				<div style={{ width: "380px", height: "400px" }}>
					<div className="relative w-full overflow-hidden">
						<div className="flex justify-center flex-col items-center">
							<div
								className="flex transition-transform duration-500 w-fit h-fit"
								style={{
									transform: `translateX(-${activeImgCarousel * 100}%)`,
								}}
							>
								{blok.blocks[0].component.includes("image") &&
									blok.blocks.map(carouselData => (
										<ImageCarousel
											blok={blok}
											carouselData={carouselData}
											activeImgCarousel={activeImgCarousel}
											goToNextImg={goToNextImg}
										/>
									))}
							</div>
							{blok.blocks.length > 1 && (
								<div className="my-2 w-full flex gap-2">
									{blok.blocks.map((_, index) => (
										<button
											onClick={() => goToNextImg(index)}
											key={index}
											className={`${
												activeImgCarousel === index
													? "bg-neutral"
													: "bg-base-300"
											} pointer h-1.5 flex-1`}
										/>
									))}
								</div>
							)}
						</div>
					</div>
				</div>
			</div> */}

			{/* infinte carousel */}
			<div
				className="relative overflow-hidden w-full"
				onMouseEnter={stopInterval}
				onMouseLeave={resumeInterval}
			>
				<div
					className="flex transition-transform duration-500 ease-in-out"
					style={{
						transform: `translateX(-${
							(activeImgCarousel * 100) / visibleImages
						}%)`,
					}}
				>
					{blok.blocks[0].component.includes("image") &&
						blok.blocks
							.concat(blok.blocks)
							.map(carouselData => (
								<ImageCarousel
									carouselData={carouselData}
									blok={blok}
									visibleImages={visibleImages}
								/>
							))}
				</div>
			</div>
		</>
	)
}

export default ImageCarouselBlock
