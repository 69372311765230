import React, { useEffect, useRef, useState } from "react"
import Carousel from "./carousel"

const CarouselBlock = ({ blok }) => {
	const intervalRef = useRef(null)
	const [activeCarousel, setActiveCarousel] = useState(0)

	useEffect(() => {
		startInterval()
		return () => clearInterval(intervalRef.current)
	}, [])

	const startInterval = () => {
		clearInterval(intervalRef.current)
		intervalRef.current = setInterval(() => {
			nextSlide()
		}, 5000)
	}

	const nextSlide = () => {
		setActiveCarousel(previndex => (previndex + 1) % blok.blocks.length)
	}

	const goToSlide = index => {
		setActiveCarousel(index)
		startInterval()
	}

	const stopInterval = () => {
		clearInterval(intervalRef.current)
	}

	const resumeInterval = () => {
		startInterval()
	}

	return (
		<>
			<div
				key={blok._uid}
				className="relative w-full overflow-hidden"
				onMouseEnter={stopInterval}
				onMouseLeave={resumeInterval}
			>
				<div
					className="flex transition-transform duration-500 w-fit h-fit"
					style={{ transform: `translateX(-${activeCarousel * 100}%)` }}
				>
					{!blok.blocks[0].component.includes("image") &&
						blok.blocks.map(carouselData => (
							<Carousel
								blok={blok}
								carouselData={carouselData}
								activeCarousel={activeCarousel}
								goToSlide={goToSlide}
							/>
						))}
				</div>
			</div>
		</>
	)
}

export default CarouselBlock
