export const updateResources = (data) => {
    const allResources = data.allFreeResources.nodes;
    const allResourceInterestGroups = data.allResourceInterestGroups.nodes
    const allInterestGroupsWithName = data.allInterestGroup.nodes

    allResources.sort((a, b) => {
        const dateA = new Date(a.authorizedOn);
        const dateB = new Date(b.authorizedOn);

        return dateB - dateA;
    });

    allResources.forEach((resource, index) => {
        const matchingInterestGroup = allResourceInterestGroups.find(
            interestGroup => interestGroup.fileId === resource.file
        )

        if (matchingInterestGroup) {
            // Update existing resource in allResources array
            allResources[index] = {
                ...resource,
                interestGroups: matchingInterestGroup.interestGroups,
            }
            return
        }
    })
    return updateInterestGroupNames(allResources, allInterestGroupsWithName)
}

export const updateInterestGroupNames = (allResources, allInterestGroupsWithName) => {
    allResources.forEach((resource, index) => {
        // Check if the resource has interestGroups
        if (resource.interestGroups && resource.interestGroups.length > 0) {
            // Update the interestGroup names based on matching interestGroupId
            const updatedInterestGroups = resource.interestGroups
                .map(interestGroupId => {
                    const matchingInterestGroup = allInterestGroupsWithName.find(
                        group => group.interestGroupId === interestGroupId
                    )

                    // If there is a match, return the name and id, otherwise keep the original id
                    return matchingInterestGroup
                        ? { id: interestGroupId, name: matchingInterestGroup.name, isRTDFilter:  matchingInterestGroup.isRTDFilter}
                        : null
                })
                .filter(Boolean) // Filter out null values

            // Add new interest groups to the existing ones
            const combinedInterestGroups = [
                ...new Set([...resource.interestGroups, ...updatedInterestGroups]),
            ]

            // Update the existing resource in AllResourcesList
            allResources[index] = {
                ...resource,
                interestGroups: combinedInterestGroups,
            }
        }
    })
    return allResources;
}

export const checkRTDResource = (data) => {
    const hasRTDInterestGroup = data.some(item => 
        item.interestGroups?.some(group => 
            typeof group === 'object' && group.isRTDFilter === true
        )
    );

    return hasRTDInterestGroup;
};
