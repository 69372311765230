import React from "react"
import RichText from "./richText"
import NavLink from "./navLink"

const Carousel = ({ blok, carouselData, activeCarousel, goToSlide }) => {
	return (
		<div
			key={carouselData.label}
			className={`w-fit min-w-full bg-gray-200 p-2`}
		>
			<div className="h-full max-w-full flex flex-col md:flex-row justify-between">
				<img
					className="h-56 mx-auto rounded-lg md:hidden object-center"
					src={carouselData.content.filename}
				/>
				<div className="flex flex-grow h-full justify-between flex-col place-self-center place-items-center md:items-start md:place-self-stretch md:justify-evenly md:align-center w-3/4 md:w-1/2 lg:w-1/4 mx-2 lg:mx-10 my-2">
					<div>
						<h2 className="text-neutral text-xl lg:text-2xl xl:text-4xl font-bold my-2">
							{carouselData.label}
						</h2>
						<RichText content={carouselData.abstract} />

						{carouselData.callToAction && (
							<NavLink
								link={carouselData.callToActionLink}
								isSocialLink={false}
								className={
									"text-white justify-between flex rounded my-4 gap-2 text-sm lg:text-md font-bold w-56 md:w-32 lg:w-40 xl:w-56 p-2 bg-primary hover:opacity-80"
								}
							>
								{carouselData.callToAction}
								<svg
									xmlns="http://www.w3.org/2000/svg"
									fill="none"
									height={20}
									width={20}
									viewBox="0 0 24 24"
									strokeWidth={1.5}
									stroke="currentColor"
									className="size-1"
								>
									<path
										strokeLinecap="round"
										strokeLinejoin="round"
										d="m8.25 4.5 7.5 7.5-7.5 7.5"
									/>
								</svg>
							</NavLink>
						)}
					</div>
					<div>
						{blok.blocks.length > 1 && (
							<div className="my-2 flex gap-2">
								{blok.blocks.map((_, index) => (
									<button
										onClick={() => goToSlide(index)}
										key={index}
										className={`${
											activeCarousel === index ? "bg-neutral" : "bg-base-300"
										} pointer h-1.5 w-24`}
									/>
								))}
							</div>
						)}
					</div>
				</div>
				<img
					className="w-full md:w-1/2 h-96 rounded-lg hidden md:block object-center"
					src={carouselData.content.filename}
				/>
			</div>
		</div>
	)
}

export default Carousel
