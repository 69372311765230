import { Link, graphql, useStaticQuery } from "gatsby"
import { useEffect, useState } from "react"
import Resource from "../resources/resource"
import CustomCarousel from "../customComponents/CustomCarousel"

const HomeNews = () => {
	const [news, setNews] = useState([])
	const data = useStaticQuery(graphql`
		{
			newsItems: allNewsItem(sort: { order: DESC, fields: authorizedOn }) {
				nodes {
					newsId
					permalink
					name
					excerpt
					authorizedOn
					abstract
					thumbnailImages {
						childImageSharp {
							gatsbyImageData
						}
					}
				}
			}
		}
	`)

	useEffect(() => {
		setNews(
			data.newsItems.nodes
				.sort((a, b) => new Date(b.authorizedOn) - new Date(a.authorizedOn))
				.slice(0, 6)
		)
	}, [])

	return (
		<div className="bg-gray-200 px-2 md:px-16 lg:px-20">
			<div className="flex flex-col items-center md:flex-row md:justify-between px-4 pt-10 md:pt-16 pb-1  lg:px-12">
				<h1 className="text-neutral font-bold text-2xl xl:text-3xl text-center md:text-left md:ml-2 md:text-2xl mb-1 md:mb-4">
					Recent News
				</h1>
				<Link to={"/news"}>
					<button className="w-32 md:w-auto rounded my-2 text-xs bg-primary text-white hover:opacity-80 py-2 md:px-8">
						View All
					</button>
				</Link>
			</div>
			<div className="pb-16">
				<CustomCarousel slidesPerView={3}>
					{news.map(item => (
						<Resource
							key={item.newsId}
							resource={item}
							useCardStyle={true}
							category={"news"}
						/>
					))}
				</CustomCarousel>
			</div>
		</div>
	)
}

export default HomeNews
